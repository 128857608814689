import { Injectable } from '@angular/core';

import { UserSessionService } from '@services/user-session.service';

@Injectable({
  providedIn: 'root'
})
export class VTDButtonService {

  constructor(private userSessionService: UserSessionService) { }

  play(url: string, classifiedAdID: string) {
    this.userSessionService.recordVTDAccess(classifiedAdID);
    window.parent.postMessage({eventType: 'showModal', url: url, width: 900, height: 500}, '*');
  }

}