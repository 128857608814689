import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CarFaxResponse, EndUsersService } from './end-users.service';
import { UserSessionService } from './user-session.service';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CarFaxService {

  constructor(
    private endUsersService: EndUsersService,
    private router: Router,
    private userSessionService: UserSessionService,
  ) { }

  pullCarFaxReport(classifiedAdID: string): void {
    if (this.userSessionService.isLoggedIn) {
      const newWin = window.open('', undefined, 'width=800,height=800');
        if (newWin) {
          newWin.document.body.style.display = 'flex';
          newWin.document.body.style.justifyContent = 'center';
          newWin.document.body.style.alignItems = 'center';
          newWin.document.body.style.width = '100vw';
          newWin.document.body.style.height = '100vh';
          newWin.document.body.style.backgroundColor = '#222';
          newWin.document.body.style.fontSize = '2rem';
          newWin.document.body.style.color = '#fff';
          newWin.document.body.innerHTML = '<p>Accessing vehicle history report. Please stand by&hellip;</p>';
          this.endUsersService.getCarFax(classifiedAdID).subscribe((result: CarFaxResponse) => {
            newWin.location.href = result.url;
          });
        } else {
          window.parent.postMessage({eventType: 'alert', alertMessage: 'Unable to open vehicle history window. Disabling your popup blocker may help.'}, environment.apiBaseUrl);
        }
    } else {
      const state = {
        msg: 'Please log in or create an account to download the free vehicle history report.',
      };
      this.router.navigate(['/login'], {state: state});
    }
  }

}
